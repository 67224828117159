
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .aside {
        transform: translateY(-40px);
        > .tit {
            margin-bottom: 12px;
        }
    }
}

.aside-btn {
    --btn-outline-border-width: 1px;
    &.v-btn.v-btn--outlined.v-btn--icon.v-size--large {
        border-top: 0;
        border-radius: 0 0 6px 6px;
        height: calc(var(--btn-height-lg) / 2) !important;
        .v-icon {
            color: var(--v-grey-base);
        }
    }
}
