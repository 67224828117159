
.v-text-field {
    --input-padding-dense: 0 12px;
    ::v-deep {
        .v-input__append-inner {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-text-field {
        --input-padding-dense: 0 12px 0 16px;
        width: 100%;
        min-width: 180px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
